var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Felhasználó"}},[_c('validation-provider',{attrs:{"name":"felhasználó","rules":"required","vid":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.userOptions,"filterable":false,"clearable":false},on:{"search":_vm.searchUsers,"input":_vm.setUserData},model:{value:(_vm.formData.user),callback:function ($$v) {_vm.$set(_vm.formData, "user", $$v)},expression:"formData.user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Név"}},[_c('validation-provider',{attrs:{"name":"név","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email cím"}},[_c('validation-provider',{attrs:{"name":"email cím","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Telefonszám"}},[_c('validation-provider',{ref:"phoneValidator",attrs:{"name":"telefonszám","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"error":errors.length > 0,"placeholder":"telefonszám","default-country-code":"HU","color":"#42968E","valid-color":"#404656","error-color":"#EA5455","dark-color":"#283046","no-example":true,"dark":_vm.$store.state.appConfig.layout.skin === 'dark',"translations":{
                    countrySelectorLabel: 'Ország',
                    countrySelectorError: 'Válassz egy országot',
                    phoneNumberLabel: 'Telefonszám'
                  }},on:{"input":_vm.checkIfCanCreateReservation,"update":_vm.phoneChanged},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ország"}},[_c('validation-provider',{attrs:{"name":"ország","rules":"required","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.countryOptions},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Irányítószám"}},[_c('validation-provider',{attrs:{"name":"irányítószám","rules":"required","vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.zip),callback:function ($$v) {_vm.$set(_vm.formData, "zip", $$v)},expression:"formData.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Település"}},[_c('validation-provider',{attrs:{"name":"település","rules":"required","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cím"}},[_c('validation-provider',{attrs:{"name":"cím","rules":"required","vid":"address1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.address1),callback:function ($$v) {_vm.$set(_vm.formData, "address1", $$v)},expression:"formData.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cím kiegészítés"}},[_c('validation-provider',{attrs:{"name":"cím kiegészítés","vid":"address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.address2),callback:function ($$v) {_vm.$set(_vm.formData, "address2", $$v)},expression:"formData.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Horgászhely"}},[_c('validation-provider',{attrs:{"name":"horgászhely","rules":"required","vid":"fishingSpot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.fishingSpotOptions},on:{"input":_vm.fishingSpotChanged},model:{value:(_vm.formData.fishingSpot),callback:function ($$v) {_vm.$set(_vm.formData, "fishingSpot", $$v)},expression:"formData.fishingSpot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tervezett érkezés"}},[_c('validation-provider',{attrs:{"name":"tervezett érkezés","rules":"required","vid":"startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},on:{"input":_vm.fishingSpotChanged},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tervezett távozás"}},[_c('validation-provider',{attrs:{"name":"tervezett távozás","rules":"required","vid":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},on:{"input":_vm.fishingSpotChanged},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('validation-observer',{ref:"reservationData"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('h4',[_vm._v("Résztvevők")])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Résztvevők összesen")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"résztvevők összesen","rules":"required","vid":"inTotal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',[_vm._v(_vm._s(_vm.formData.participants.inTotal.count)+" fő")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.participants.inTotal.count),expression:"formData.participants.inTotal.count"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formData.participants.inTotal.count)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.participants.inTotal, "count", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.inTotal.cost))+" Ft ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Felnőtt és ifi horgászok")]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"felnőtt és ifi horgászok","rules":"required","vid":"adultYoungster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formData.participants.adultYoungster.count)+" fő ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.participants.adultYoungster.count),expression:"formData.participants.adultYoungster.count"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formData.participants.adultYoungster.count)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.participants.adultYoungster, "count", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-1",attrs:{"sm":"12","md":"6"}},[_vm._v("Vendéghorgász")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"vendéghorgász","rules":"required","vid":"guestAll"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.guestAll.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.guestAll, "count", $$v)},expression:"formData.participants.guestAll.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.guestAll.cost))+" Ft ")])],1),(_vm.formData.participants.guestAll.count.value > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-2",attrs:{"sm":"12","md":"6"}},[_vm._v("ebből ifi")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"ifi","rules":"required","vid":"guestYoungster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.guestYoungster.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.guestYoungster, "count", $$v)},expression:"formData.participants.guestYoungster.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,939673850)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.guestYoungster.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.participants.guestAll.count.value > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-2",attrs:{"sm":"12","md":"6"}},[_vm._v("ebből 70 év feletti")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"70 év feletti","rules":"required","vid":"guestOlderThan70Years"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.guestOlderThan70Years.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.guestOlderThan70Years, "count", $$v)},expression:"formData.participants.guestOlderThan70Years.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,848518328)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.guestOlderThan70Years.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.participants.guestAll.count.value > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-2",attrs:{"sm":"12","md":"6"}},[_vm._v("ebből hölgy")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"hölgy","rules":"required","vid":"guestLady"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.guestLady.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.guestLady, "count", $$v)},expression:"formData.participants.guestLady.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2238631984)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.guestLady.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.participants.guestAll.count.value > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-2",attrs:{"sm":"12","md":"6"}},[_vm._v("állami horgászjegyet váltana")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"állami horgászjegyet váltana","rules":"required","vid":"guestWantsStateTicket"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.guestWantsStateTicket.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.guestWantsStateTicket, "count", $$v)},expression:"formData.participants.guestWantsStateTicket.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2534537388)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.guestWantsStateTicket.cost))+" Ft ")])],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-1",attrs:{"sm":"12","md":"6"}},[_vm._v("BSHE alaptag")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"BSHE alaptag","rules":"required","vid":"BSHEBasicMember"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.BSHEBasicMember.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.BSHEBasicMember, "count", $$v)},expression:"formData.participants.BSHEBasicMember.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.BSHEBasicMember.cost))+" Ft ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-1",attrs:{"sm":"12","md":"6"}},[_vm._v("BSHE teljes jogú tag")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"BSHE teljes jogú tag","rules":"required","vid":"BSHEFullMember"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.BSHEFullMember.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.BSHEFullMember, "count", $$v)},expression:"formData.participants.BSHEFullMember.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.BSHEFullMember.cost))+" Ft ")])],1),(_vm.childrenEnabled)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Gyermek horgászok")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"gyermek horgászok","rules":"required","vid":"child"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.childAttendantOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.child.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.child, "count", $$v)},expression:"formData.participants.child.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1842244311)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.child.cost))+" Ft ")])],1):_vm._e(),(_vm.childrenEnabled && _vm.formData.participants.child.count.value > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"participants-level-2",attrs:{"sm":"12","md":"6"}},[_vm._v("területi engedélyt váltana")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"területi engedélyt váltana","rules":"required","vid":"childWantsRegionalTicket"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.adultYoungsterOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.childWantsRegionalTicket.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.childWantsRegionalTicket, "count", $$v)},expression:"formData.participants.childWantsRegionalTicket.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2609591892)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.childWantsRegionalTicket.cost))+" Ft ")])],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Kísérők")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"kísérők","rules":"required","vid":"attendant"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","options":_vm.childAttendantOptions,"clearable":false},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.participants.attendant.count),callback:function ($$v) {_vm.$set(_vm.formData.participants.attendant, "count", $$v)},expression:"formData.participants.attendant.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.participants.attendant.cost))+" Ft ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('h4',[_vm._v("Opciók")])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Opciók összesen")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_vm._v(" ")]),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.inTotal.cost))+" Ft ")])],1),(_vm.formData.fishingSpot.canUseGeneralFishingMethod)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Horgászmódszer")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"horgászmódszer","rules":"required","vid":"fishingMethod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","clearable":false,"options":_vm.fishingMethodOptions},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.fishingMethod.value),callback:function ($$v) {_vm.$set(_vm.formData.options.fishingMethod, "value", $$v)},expression:"formData.options.fishingMethod.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2874492982)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.fishingMethod.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.fishingSpot.canUseBoat)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Csónak etetéshez")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"csónak etetéshez","rules":"required","vid":"boatForFeeding"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","clearable":false,"options":_vm.yesNoOptions},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.boatForFeeding.value),callback:function ($$v) {_vm.$set(_vm.formData.options.boatForFeeding, "value", $$v)},expression:"formData.options.boatForFeeding.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,694907257)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.boatForFeeding.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.fishingSpot.hasPowerConsumption)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Áramfelvétel")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"áramfelvétel","rules":"required","vid":"powerConsumption"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","clearable":false,"options":_vm.yesNoOptions},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.powerConsumption.value),callback:function ($$v) {_vm.$set(_vm.formData.options.powerConsumption, "value", $$v)},expression:"formData.options.powerConsumption.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3462309794)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.powerConsumption.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.fishingSpot.hasParkingFee)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Gépjármű belépő")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"gépjármű belépő","rules":"required","vid":"vehicleEntranceFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","clearable":false,"options":_vm.vehicleOptions},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.vehicleEntranceFee.value),callback:function ($$v) {_vm.$set(_vm.formData.options.vehicleEntranceFee, "value", $$v)},expression:"formData.options.vehicleEntranceFee.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1337277876)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.vehicleEntranceFee.cost))+" Ft ")])],1):_vm._e(),(_vm.formData.fishingSpot.canUseCaravan)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Lakókocsi belépő")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"lakókocsi belépő","rules":"required","vid":"caravanEntranceFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":"ltr","label":"title","clearable":false,"options":_vm.vehicleOptions},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.caravanEntranceFee.value),callback:function ($$v) {_vm.$set(_vm.formData.options.caravanEntranceFee, "value", $$v)},expression:"formData.options.caravanEntranceFee.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1776872836)})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.caravanEntranceFee.cost))+" Ft ")])],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('h4',[_vm._v("Összesítés")])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Minimálisan fizetendő díj")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"minimálisan fizetendő díj","rules":"required","vid":"defaultFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',[_vm._v(_vm._s(_vm.$formatters.formatPriceText(_vm.formData.minimumFee.defaultFee))+" Ft")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.minimumFee.defaultFee),expression:"formData.minimumFee.defaultFee"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formData.minimumFee.defaultFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.minimumFee, "defaultFee", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.minimumFee.cost))+" Ft ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Egyéb díj")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"egyéb díj","vid":"other"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number"},on:{"input":_vm.calculateCountAndCost},model:{value:(_vm.formData.options.other.cost),callback:function ($$v) {_vm.$set(_vm.formData.options.other, "cost", $$v)},expression:"formData.options.other.cost"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("Ft")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.formData.options.other.cost))+" Ft ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Egyéb megjegyzés")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"egyéb megjegyzés","vid":"adminComment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.formData.adminComment),callback:function ($$v) {_vm.$set(_vm.formData, "adminComment", $$v)},expression:"formData.adminComment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v("   ")])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_vm._v("Foglalás összesen")]),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_vm._v("   ")]),_c('b-col',{attrs:{"sm":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$formatters.formatPriceText(_vm.grossTotal))+" Ft ")])],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit","disabled":!_vm.canCreateReservation},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Foglalás ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }